<template>
    <tr v-if="item.quantity">
        <td><strong>{{item.product.name}} {{item.product.type}}</strong></td>
        <td>{{(item.quantity).toLocaleString()}}</td>
        <td>{{getLabourUnitPrice(item).toLocaleString()}}</td>
        <td class="pr-0">{{getLabourPrice(item).toLocaleString()}}</td>
        <td>{{getLabourUnitPriceVAT(item).toLocaleString()}}</td>
        <td class="pr-0">{{getLabourPriceVAT(item).toLocaleString()}}</td>
    </tr>
</template>

<script>
    export default {
        name: 'LabourItem',
        props:{ 
            initialItem: Object
        },
        data(){
            return {
                item:this.initialItem,
                cart: {
                    items:[]
                }
            }
        },
        mounted(){
            this.cart = this.$store.state.cart
        },
        computed: {
            cartTotalLength(){ // return total amount of blocks selected
            return this.cart.items.reduce((acc, curVal) => {
                return  acc += curVal.quantity
            }, 
            0)
        },
        HalfMobilisationCost(){
            return this.MobilisationCost / 2
        },
        MobilisationCost(){
            return Math.ceil(this.$store.state.mobilisation_cost / this.cartTotalLength) * this.cartTotalLength
        },
        VATCost(){
            return Math.ceil(parseFloat((this.cartSelfPriceVat / this.cartTotalLength)).toFixed(3)) * this.cartTotalLength
        },
        unitMobilisationCost() {
        // Guard against division by zero
        return this.cartTotalLength > 0 
            ? Math.ceil(this.$store.state.mobilisation_cost / this.cartTotalLength)
            : 0
        },
        cartSelfPrice(){
            if(parseInt(this.$store.state.location_distance) <= 15){
                console.log('Mobilisation cost is',this.unitMobilisationCost)
                return this.cart.items.reduce((acc, curVal) => {
                    return acc += (Math.ceil(curVal.quantity * (curVal.product.base_labour
                    + 4 * curVal.product.productivity_factor * parseInt(this.$store.state.location_distance) 
                    + curVal.product.productivity_factor * parseInt(this.$store.state.location_distance))) + 
                    this.unitMobilisationCost
                    )
                }, 0)
            } else if(parseInt(this.$store.state.location_distance) > 15 && parseInt(this.$store.state.location_distance) <= 150){
                return this.cart.items.reduce((acc, curVal) => {
                    return acc += (Math.ceil(curVal.quantity * (curVal.product.base_labour
                    + 4 * curVal.product.productivity_factor * 15 
                    + curVal.product.productivity_factor * parseInt(this.$store.state.location_distance)) 
                    + this.unitMobilisationCost
                    ))
                }, 0)
            } else {
                return this.cart.items.reduce((acc, curVal) => {
                    return acc += (Math.ceil(curVal.quantity * (curVal.product.base_labour
                    + 4 * curVal.product.productivity_factor * 15 
                    + curVal.product.productivity_factor * 150 
                    + this.unitMobilisationCost
                    )))
                }, 0)
            }
        },
        unitVATCost(){
            return this.cartTotalLength > 0
            ? Math.ceil((this.cartSelfPriceVat / this.cartTotalLength))
            : 0
        },
        cartSelfPriceVat(){
            return Math.ceil(0.18 * this.cartSelfPrice);
        },
        },
        methods:{
            getLabourPrice(item){
                if(parseInt(this.$store.state.location_distance) <= 15){
                    return ((item.quantity * (item.product.base_labour
                    + 4 * item.product.productivity_factor * parseInt(this.$store.state.location_distance) 
                    + item.product.productivity_factor * parseInt(this.$store.state.location_distance)
                    + this.unitMobilisationCost
                    )))
                } else if(parseInt(this.$store.state.location_distance) > 15 && parseInt(this.$store.state.location_distance) <= 150){
                    return ((item.quantity * (item.product.base_labour
                    + 4 * item.product.productivity_factor * 15
                    + item.product.productivity_factor * parseInt(this.$store.state.location_distance)
                    + this.unitMobilisationCost
                    )))
                } else {
                    return ((item.quantity * (item.product.base_labour
                    + 4 * item.product.productivity_factor * 15
                    + item.product.productivity_factor * 150
                    + this.unitMobilisationCost
                    )))
                }
            },
            getLabourPriceVAT(item){
                if(parseInt(this.$store.state.location_distance) <= 15){
                    return ((item.quantity * Math.ceil(item.product.base_labour
                    + 4 * item.product.productivity_factor * parseInt(this.$store.state.location_distance) 
                    + item.product.productivity_factor * parseInt(this.$store.state.location_distance)
                    + this.unitMobilisationCost
                    + this.unitVATCost
                    )))
                } else if(parseInt(this.$store.state.location_distance) > 15 && parseInt(this.$store.state.location_distance) <= 150){
                    return ((item.quantity * Math.ceil(item.product.base_labour
                    + 4 * item.product.productivity_factor * 15
                    + item.product.productivity_factor * parseInt(this.$store.state.location_distance)
                    + this.unitMobilisationCost
                    + this.unitVATCost
                    )))
                } else {
                    return ((item.quantity * Math.ceil(item.product.base_labour
                    + 4 * item.product.productivity_factor * 15
                    + item.product.productivity_factor * 150
                    + this.unitMobilisationCost
                    + this.unitVATCost
                    )))
                }
            },
            getLabourUnitPrice(item){
                if(parseInt(this.$store.state.location_distance) <= 15){
                    return (Math.ceil(item.product.base_labour
                    + 4 * item.product.productivity_factor * parseInt(this.$store.state.location_distance) 
                    + item.product.productivity_factor * parseInt(this.$store.state.location_distance)
                    + this.unitMobilisationCost
                    ))
                } else if(parseInt(this.$store.state.location_distance) > 15 && parseInt(this.$store.state.location_distance) <= 150){
                    return (Math.ceil(item.product.base_labour
                    + 4 * item.product.productivity_factor * 15
                    + item.product.productivity_factor * parseInt(this.$store.state.location_distance)
                    + this.unitMobilisationCost
                    ))
                } else {
                    return (Math.ceil(item.product.base_labour
                    + 4 * item.product.productivity_factor * 15
                    + item.product.productivity_factor * 150
                    + this.unitMobilisationCost
                    ))
                }
            },
            getLabourUnitPriceVAT(item){
                if(parseInt(this.$store.state.location_distance) <= 15){
                    return (Math.ceil(item.product.base_labour
                    + 4 * item.product.productivity_factor * parseInt(this.$store.state.location_distance) 
                    + item.product.productivity_factor * parseInt(this.$store.state.location_distance)
                    + this.unitMobilisationCost
                    + this.unitVATCost
                    ))
                } else if(parseInt(this.$store.state.location_distance) > 15 && parseInt(this.$store.state.location_distance) <= 150){
                    return (Math.ceil(item.product.base_labour
                    + 4 * item.product.productivity_factor * 15
                    + item.product.productivity_factor * parseInt(this.$store.state.location_distance)
                    + this.unitMobilisationCost
                    + this.unitVATCost
                    ))
                } else {
                    return (Math.ceil(item.product.base_labour
                    + 4 * item.product.productivity_factor * 15
                    + item.product.productivity_factor * 150
                    + this.unitMobilisationCost
                    + this.unitVATCost
                    ))
                }
            }
        }
    }
</script>

<style scoped>
td{
    padding: 4px;
    border:1px solid #000;
}

th{
	padding: 6px;
    border:1px solid #000;
}
</style>