<template>
        <tr v-if="item.quantity">
            <td class=""><strong>{{ item.product.name}} {{item.product.type}}</strong></td>
            <td>{{ (item.quantity).toLocaleString() }}</td>
            <td v-if="this.$store.state.cement_price != ''">{{ getItemPrice(item).toLocaleString()}}</td>
            <td v-else><div class="loader"></div></td>
            <td v-if="this.$store.state.cement_price != ''" class="">{{ getItemPriceTotal(item).toLocaleString()}}</td>
            <td v-else><div class="loader"></div></td>
            <td v-if="this.$store.state.cement_price != ''">{{ getItemPriceVAT(item).toLocaleString()}}</td>
            <td v-else><div class="loader"></div></td>
            <td v-if="this.$store.state.cement_price != ''" class="">{{ getItemPriceTotalVAT(item).toLocaleString()}}</td>
            <td v-else><div class="loader"></div></td>
            <!--<td class="pr-0"><button class="delete" @click="removeFromCart(item)"></button></td>-->
        </tr>    
</template>

<script>
    export default {
        name: 'CartItem',
        props: {
            initialItem: Object
        },
        data(){
            return {
                item: this.initialItem,
                labour_price:'',
                cart: {
                    items:[]
                }
            }
        },
        mounted(){
            this.cart = this.$store.state.cart
        },

        computed: {
            cartTotalLength(){ // return total amount of blocks selected
            return this.cart.items.reduce((acc, curVal) => {
                return  acc += curVal.quantity
            }, 
            0)
        },
        unitMobilisationCost() {
        // Guard against division by zero
        return this.cartTotalLength > 0 
            ? Math.ceil(this.$store.state.mobilisation_cost / this.cartTotalLength)
            : 0
        },
        unitVATCost(){
            return this.cartTotalLength > 0
            ? parseFloat((this.cartSelfPriceVat / this.cartTotalLength).toFixed(3))
            : 0
        },
        cartSelfPriceVat(){
            if(parseInt(this.$store.state.location_distance) <= 15){
                return this.cart.items.reduce((acc, curVal) => {
                    return acc += (Math.ceil(0.18 * (curVal.quantity * (curVal.product.base_labour
                    + 4 * curVal.product.productivity_factor * parseInt(this.$store.state.location_distance) 
                    + curVal.product.productivity_factor * parseInt(this.$store.state.location_distance)))
                    ))
                }, Math.ceil(0.18 * parseInt(this.$store.state.mobilisation_cost)))
            } else if(parseInt(this.$store.state.location_distance) > 15 && parseInt(this.$store.state.location_distance) <= 150){
                return this.cart.items.reduce((acc, curVal) => {
                    return acc += (Math.ceil(0.18 * curVal.quantity * (curVal.product.base_labour
                    + 4 * curVal.product.productivity_factor * 15 
                    + curVal.product.productivity_factor * parseInt(this.$store.state.location_distance)
                    )))
                }, Math.ceil(0.18 * parseInt(this.$store.state.mobilisation_cost)))
            } else {
                return this.cart.items.reduce((acc, curVal) => {
                    return acc += (Math.ceil(0.18 * curVal.quantity * (curVal.product.base_labour
                    + 4 * curVal.product.productivity_factor * 15 
                    + curVal.product.productivity_factor * 150
                    )))
                }, Math.ceil(0.18 * parseInt(this.$store.state.mobilisation_cost)))
            }
        },
        },

        methods:{
            getItemPrice(item){
                if (this.cartTotalLength === 0) {
                    return 0
                }

                if(parseInt(this.$store.state.location_distance) <= 15){
                    //console.log('this is the mobilisation cost', this.unitMobilisationCost)
                    return (Math.ceil(item.product.material_cost + item.product.base_labour
                    + 4 * item.product.productivity_factor * parseInt(this.$store.state.location_distance) 
                    + item.product.productivity_factor * parseInt(this.$store.state.location_distance) 
                    + this.unitMobilisationCost
                    ))
                } else if(parseInt(this.$store.state.location_distance) > 15 && parseInt(this.$store.state.location_distance) <= 150){
                    //console.log('this is the mobilisation cost', this.unitMobilisationCost)
                    return (Math.ceil(item.product.material_cost + item.product.base_labour
                    + 4 * item.product.productivity_factor * 15
                    + item.product.productivity_factor * parseInt(this.$store.state.location_distance)
                    + this.unitMobilisationCost
                    ))
                } else {
                    //console.log('this is the mobilisation cost', this.unitMobilisationCost)
                    return (Math.ceil(item.product.material_cost + item.product.base_labour
                    + 4 * item.product.productivity_factor * 15
                    + item.product.productivity_factor * 150
                    + this.unitMobilisationCost
                    ))
                }
            },
            getItemPriceVAT(item){
                if(parseInt(this.$store.state.location_distance) <= 15){
                    return (Math.ceil(item.product.material_cost + item.product.base_labour
                    + 4 * item.product.productivity_factor * parseInt(this.$store.state.location_distance) 
                    + item.product.productivity_factor * parseInt(this.$store.state.location_distance)
                    + this.unitMobilisationCost
                    + this.unitVATCost
                    ))
                } else if(parseInt(this.$store.state.location_distance) > 15 && parseInt(this.$store.state.location_distance) <= 150){
                    return (Math.ceil(item.product.material_cost + item.product.base_labour
                    + 4 * item.product.productivity_factor * 15
                    + item.product.productivity_factor * parseInt(this.$store.state.location_distance)
                    + this.unitMobilisationCost
                    + this.unitVATCost
                    ))
                } else {
                    return (Math.ceil(item.product.material_cost + item.product.base_labour
                    + 4 * item.product.productivity_factor * 15
                    + item.product.productivity_factor * 150
                    + this.unitMobilisationCost
                    + this.unitVATCost
                    ))
                }
            },
            getItemPriceTotal(item){
                if(parseInt(this.$store.state.location_distance) <= 15){
                    return (Math.ceil( item.quantity * (item.product.material_cost + item.product.base_labour
                    + 4 * item.product.productivity_factor * parseInt(this.$store.state.location_distance) 
                    + item.product.productivity_factor * parseInt(this.$store.state.location_distance)
                    + this.unitMobilisationCost
                    )))
                } else if(parseInt(this.$store.state.location_distance) > 15 && parseInt(this.$store.state.location_distance) <= 150){
                    return (Math.ceil( item.quantity * (item.product.material_cost + item.product.base_labour
                    + 4 * item.product.productivity_factor * 15
                    + item.product.productivity_factor * parseInt(this.$store.state.location_distance)
                    + this.unitMobilisationCost
                    )))
                } else {
                    return (Math.ceil(item.quantity * (item.product.material_cost + item.product.base_labour
                    + 4 * item.product.productivity_factor * 15
                    + item.product.productivity_factor * 150
                    + this.unitMobilisationCost
                    )))
                }
            },
            getItemPriceTotalVAT(item){
                if(parseInt(this.$store.state.location_distance) <= 15){
                    return (( item.quantity * Math.ceil(item.product.material_cost + item.product.base_labour
                    + 4 * item.product.productivity_factor * parseInt(this.$store.state.location_distance) 
                    + item.product.productivity_factor * parseInt(this.$store.state.location_distance)
                    + this.unitMobilisationCost
                    + this.unitVATCost
                    )))
                } else if(parseInt(this.$store.state.location_distance) > 15 && parseInt(this.$store.state.location_distance) <= 150){
                    return (( item.quantity * Math.ceil(item.product.material_cost + item.product.base_labour
                    + 4 * item.product.productivity_factor * 15
                    + item.product.productivity_factor * parseInt(this.$store.state.location_distance)
                    + this.unitMobilisationCost
                    + this.unitVATCost
                    )))
                } else {
                    return ((item.quantity * Math.ceil(item.product.material_cost + item.product.base_labour
                    + 4 * item.product.productivity_factor * 15
                    + item.product.productivity_factor * 150
                    + this.unitMobilisationCost
                    + this.unitVATCost
                    )))
                }
            },
            getCementTotal(item){
                return Math.ceil(item.quantity / item.product.cement)
            },
            getStoneTotal(item){
                return Math.ceil(item.quantity / item.product.stone_dust)
            },
            getCementPrice(item){
                return Math.ceil(((item.quantity / item.product.cement)) * parseInt(this.$store.state.cement_price))
            },
            getStoneDustPrice(item){
                return (((item.quantity / item.product.stone_dust).toFixed(1)) * parseInt(this.$store.state.stone_dust_price))
            },
            
            updateCart(){
                localStorage.setItem('cart', JSON.stringify(this.$store.state.cart))
            },
            removeFromCart(item){
                this.$emit('removeFromCart', item)

                this.updateCart()
            },
        }

    }



</script>

<style scoped>
 td{
    padding: 5px;
    border:1px solid #000;
}

.loader {
  border: 2px solid #f3f3f3; /* Light grey */
  border-top: 2px solid #004100; /* Blue */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>