<template>
<div class="container" style="margin-top: 52px">
        <vueper-slides ref="myVueperSlides" :parallax="parallax" :parallax-fixed-content="parallaxFixedContent" fixed-height="500px" autoplay duration="4000">
        <vueper-slide
            v-for="(slide, i) in slides"
            :key="i"
            :title="slide.title"
            :content="slide.content"
            :image="slide.image">
            <template #content>
                <div class="vueperslide__content-wrapper parallxFixedContent has-text-white has-text-weight-bold">
                    <span style="font-size:36px">{{slide.title}}</span>
                    <span style="font-size:22px">{{slide.content}}</span>

                </div>
            </template>
            
        </vueper-slide>
        </vueper-slides>
    
        <div class="section">
  <div class="columns is-centered">
    <div class="column has-text-centered">
      <router-link to="/materials-calculator">
        <a class="button is-success" @click="clearCart()" style="background-color:#004100;">
          Go to Materials Calculator
        </a>
      </router-link>
    </div>
    <div class="column has-text-centered">
      <router-link to="/carbon-footprint-calculator">
        <a class="button is-success" @click="clearCart()" style="background-color:#004100;">
          Carbon footprint calculation
        </a>
      </router-link>
    </div>
    <div class="column has-text-centered">
        <a href="https://shop.tofaliafrica.com" class="button is-success" style="background-color:#004100;">
            Purchase Building Materials
        </a>
    </div>
    <div class="column has-text-centered">
        <a href="https://plans.tofaliafrica.com" class="button is-success" style="background-color:#004100;">
            Purchase Building Plans
        </a>
    </div>
    <div class="column has-text-centered">
      <router-link to="/kigezi-cement">
        <a class="button is-success" style="background-color:#004100;">
          About Kigezi Cement
        </a>
      </router-link>
    </div>
  </div>
</div>

    

    <div class="section">
        <div class="section-title">
            <h2 class=""><strong>How it Works</strong></h2>
        </div>
        <div class="row">
            <div class="column is-4 box m-2 icon-box">
                <span class="icon mb-2"><i class="fa fa-building" aria-hidden="true" style="color:white"></i></span>
                <div class="content">
                    <h4>Estimate materials needed</h4>
                    <p>We provide you with a very simple tool to help you
                        estimate how much material you may need for your project.
                    </p>
                </div>
            </div>
            <div class="column is-4 box m-2 icon-box">
                <span class="icon mb-2"><i class="fa fa-list-alt" aria-hidden="true" style="color:white"></i></span>
                <div class="content">
                    <h4>Get a quotation</h4>
                    <p>We generate a free draft quotation based on your material needs. 
                        We further support you to review your plan for an accurate quotation.
                    </p>
                </div>
            </div>
            <div class="column is-4 box m-2 icon-box">
                <span class="icon mb-2"><i class="fa fa-industry" aria-hidden="true" style="color:white"></i></span>
                <div class="content">
                    <h4>Kick off Project</h4>
                    <p>Tofali operators will inspect and execute your project and will have all updates to show progress of your projects.</p>
                </div>
            </div>
        </div>
    </div>

    <div class="planet-section has-text-white">
        <div class="overlay">
            <div class="container">
                <h2 class="has-text-weight-bold" style="font-size:30px; color:white">About Eco Concrete Ltd</h2>
                <p>A simple, sustainable and empowering business model for producing concrete blocks, planters,
                 pipes and pavers on construction sites.</p>

            </div>
        </div>
    </div>

    <div class="section">
        <div class="container">
            <div class="section-title">
                <h2><strong>Why Choose Us</strong></h2>
            </div>
            <div class="row">
                <div class="column is-3">
                    <div class="icon-box mb-2 has-text-center">
                        <span class="icon mb-2"><i class="fa fa-star" aria-hidden="true" style="color:white"></i></span>
                        <div class="content">
                            <h4>Engineers</h4>
                            
                        </div>
                    </div>
                </div>
                <div class="column is-3">
                    <div class="icon-box mb-2 has-text-center">
                        <span class="icon mb-2"><i class="fa fa-pen" aria-hidden="true" style="color:white"></i></span>
                        <div class="content">
                            <h4>Right Plans</h4>
                            
                        </div>
                    </div>
                </div>
                <div class="column is-3">
                    <div class="icon-box mb-2 has-text-center">
                        <span class="icon mb-2"><i class="fa fa-wrench" aria-hidden="true" style="color:white"></i></span>
                        <div class="content">
                            <h4>Quality Materials</h4>
                            
                        </div>
                    </div>
                </div>
                <div class="column is-3">
                    <div class="icon-box mb-2 has-text-center">
                        <span class="icon mb-2"><i class="fa fa-list-alt" aria-hidden="true" style="color:white"></i></span>
                        <div class="content">
                            <h4>Quick Completion</h4>
                            
                        </div>
                    </div>
                </div>

            </div>
        </div>
        
    </div>

    <div class="overlay-section">
        <div class="overlay">
            <div class="container">
                <h2>A simple, sustainable and empowering business model for producing concrete blocks, planters, 
                    pipes and pavers on construction sites.</h2>
            </div>
        </div>
    </div>

    
        <div class="container">
            <div class="section-title">
                <h2>Latest Projects</h2>
            </div>
            <div class="row">
                <div class="column is-4">
                    <div class="article-img">
                        <img src="https://res.cloudinary.com/celestialmk/image/upload/v1675242873/nppbl5bx8ngwopvj93wy.jpg" alt="">
                    </div>
                    <article class="message is-primary">
                        <div class="message-body">
                            <h4 class="mb-1"><strong>Mbale Site Works</strong> </h4>
                            Production of blocks for a commercial building for a client in Mbale.
                        </div>
                    </article>
                </div>
                <div class="column is-4">
                    <div class="article-img">
                        <img src="https://res.cloudinary.com/celestialmk/image/upload/v1675243106/pmhzmmfsmuekoags3vb3.jpg" alt="">
                    </div>
                </div>
                <div class="column is-4">
                    <div class="article-img">
                        <img src="https://res.cloudinary.com/celestialmk/image/upload/v1688040192/x2cwhh5wmjkif52qfxyb.jpg" alt="">
                    </div>
                    <article class="message is-primary">
                        <div class="message-body">
                            <h4 class="mb-1"><strong>Retaining Wall</strong> </h4>
                            Segmental Retaining wall constructed on Plot 20250, Kyadondo, Block 273, Kigo, Wakiso,
                            employing geogrid reinforcement for enhanced stability and structural integrity.
                        </div>
                    </article>
                </div>
            </div>
        </div>

        <div class="container mb-2 is-hidden">
            <router-link to="/portfolio">
                <a class="button is-rounded is-success"><strong>View All Projects</strong></a>
            </router-link>
        </div>

    


    

</div>
    
</template>


<script>
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'

export default {
    name: 'Index',
    data: () => ({
        //parallax: 1,
        parallaxFixedContent: false,
        autoPlaying: true,
        slides: [
            {
            title: 'Welcome to Tofali Africa',
            content: 'Order for on-site procurement for all your construction needs at your convenience.',
            image: 'https://res.cloudinary.com/celestialmk/image/upload/q_100/v1708095485/dqkszsm12jiulcywkhi2.jpg'
            },
            {
            title: 'Save up to 20% with Tofali Africa',
            content: 'Make Concrete blocks on-site with Tofali Africa. Save up to 20% and avoid getting cheated.',
            image: 'https://res.cloudinary.com/celestialmk/image/upload/v1660131156/rzxzbvwgdt8ta4bdedrf.jpg'
            //image: 'https://res.cloudinary.com/celestialmk/image/upload/v1688116255/b26lflew9krkj82mfoz1.jpg'
            //image: 'https://res.cloudinary.com/celestialmk/image/upload/v1653478763/xxdcfayopd2tfxefvcrx.jpg'
            },
            {
                image:'https://res.cloudinary.com/celestialmk/image/upload/v1688121542/damrfhrex5qb5jlzhey7.jpg'
            },
            {
                image:'https://res.cloudinary.com/celestialmk/image/upload/v1713181796/m1fechgx2o3ojv3hcgou.jpg'
            }                     
            /*
            {
            title: 'Build with Confidence',
            content: 'A competent team of engineers to implement your project.',
            image: 'https://res.cloudinary.com/celestialmk/image/upload/v1688117747/ojugcxr0ef7c9lraakl3.jpg'
            //image: 'https://res.cloudinary.com/celestialmk/image/upload/v1653478763/xxdcfayopd2tfxefvcrx.jpg'
            }*/
  ]
}),
    mounted(){
        document.title = 'Home | Tofali Africa'
    },
    components: {
        VueperSlide,
        VueperSlides
    },
    methods: {
        clearCart(){
            this.$store.commit('clearCart')
        },
    }
}
</script>

<style scoped>
@media  (min-width: 769px ) {
    .row {
        display: flex;
    }   
}
.slider-overlay{
    background-color: rgba(0,0,0,0.5);
}
.section-title h2 {
    font-size: 22px;
    line-height: 28px;
    margin: 0;
    color: #004100;
    text-align: center;
}
.is-success{
    background-color:#004100;
}
.icon-box .icon {
    display: inline-block;
    left: 0;
    top: 0;
    text-align: center;
    height: 60px;
    width: 60px;
    line-height: 58px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
    -moz-background-clip: padding-box;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    font-size: 25px;
    background-color: #004100;
}

.planet-section {
     background-image: url('https://res.cloudinary.com/celestialmk/image/upload/v1660030547/yb26wz2eyn5vttkmxegc.jpg');
     background-position: center;
}
.planet-section .overlay{
 padding: 40px 0;
 background-color: rgba(86, 212, 86, 0.28);
 text-align: center;
 background-repeat: center;
}

.container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@media only screen and (min-width: 320px) and (max-width: 567px){
.overlay-section .overlay {
    padding: 40px 0;
}}

.overlay-section{
    background-image: url('https://res.cloudinary.com/celestialmk/image/upload/v1660030547/ufjdsbwhs8czxldica3e.jpg');
    background-position: center;
}
.overlay-section .overlay {
    padding: 90px 0;
    background-color: rgba(0, 0, 0, 0.3);
}
.overlay-section .overlay h2{
    color: #fff;
    text-align: center;
    font-size: 36px;
    font-weight: bold;

}
</style>