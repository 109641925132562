<template>
  <div class="section container" style="margin-top: 50px">
        <div class="section">
          <div class="has-text-left">
            <h3><strong>SITE LOCATION</strong></h3>
          </div>   
        <vue-google-autocomplete
          ref="address"
          id="map"
          class="input"
          placeholder="Enter site location"
          v-on:placechanged="getAddressData"
          types=""
          country="ug"
        >
      </vue-google-autocomplete>
        <!--
          <div class="control ">
            <div class="select is-success" v-if = "locations">
              
              <select class="has-background-light" id="blocks">
                
                <Location
                v-for="location in locations"
                v-bind:key="location.id"
                v-bind:location="location"
                />
            </select>

            </div>
          </div>
                -->  
        </div>

        <div class="section">
          <h5 class="has-text-left has-text-weight-bold mt-2">PRODUCTS</h5>
          <div class="columns is-mobile  has-text-left has-text-weight-bold">
            <div class="column is-4">
              <h6> <strong>Size</strong></h6>
            </div>
            <div class="column is-3 has-text-centered">
              <h6><strong>Type</strong></h6>
            </div>
            <div class="column is-">
              <h6><strong>Quantity</strong></h6>
            </div>
            
          </div>
          <hr class="mt-0">
          
          <ul id="prods" :key="updateProducts">
            <template v-if="products">
              <ProductBox 
                  v-for="product in products"
                  v-bind:key="product.id"
                  v-bind:product="product"
                  
              />
            </template>
            <template v-else>
              <ProductLoading />
            </template>
            </ul>
        </div>      

    <div class="section">
      <div class="box has-text-left">
        <div class="columns is-mobile">
          <div class="column is-6 has-text-left">
            Total
          </div>
          <div class="column is-6 has-text-right">
            {{this.$store.state.total_quantity}}
          </div>
        </div>
      </div>
    </div>

    <!--&& this.$store.state.total_quantity >= 5000-->
    <div class="section" v-if="this.$store.state.location_name != ''">
      <div v-if="this.$store.state.total_quantity >= 5000" >
        <router-link to="/results" @click.native="scrollToTop" @click="createInvoice">
          <a class="button is-success pb-2">
            Calculate Total
          </a>
        </router-link>
      </div>
      <div class="section" v-else>
        <a class="button is-success pb-2" style="background-color:#004100" @click="checkTotal">
          Calculate Total
        </a>
      </div> 
    </div>


    
    <div class="section" v-else>
        <a class="button is-success pb-2" style="background-color:#004100" @click="checkLocation">
          Calculate Total
        </a>
    </div>    

  <!--

    <div class="section">
      
      <div class="field has-addons"> 
        <div class="control">
          <input type="number" class="input" min="1"
      v-model = "quantity">
    </div>
    <div class="control">
      <a class="button is-success" @click="addToCart()">
        Calculate
      </a>
    </div>
  </div>
</div>
  -->
       


  </div>


</template>

<script>
import axios from 'axios';
import swal from 'sweetalert';
import ProductBox from '../components/ProductBox.vue'
import ProductLoading from '../components/ProductLoading.vue'
import Location from '../components/Location.vue'
import Cart from './Cart.vue'
import VueGoogleAutocomplete from "vue-google-autocomplete";
import store from '@/store/index.js';
//import { ref } from 'vue';
import { mapActions, mapGetters } from 'vuex';



export default {
  name: 'HomeView',
  data(){
    return {
      product: {},
      location:{},
      selected:'',
      quantity: 4000,
      address:'',
      invoiceNumber: '',
      cart:{
          items:[]
        },
      updateProducts:0, //increment to force update
    }
  },
  mounted(){
    this.cart = this.$store.state.cart
    document.title = 'Materials Calculator | Tofali Africa'
    this.$refs.address.focus();

  },
  computed: {
    //products() {
      //return this.$store.state.products;
    //},
    //...mapGetters(["products"]),
    products(){
      if (this.$store.state.products == ''){
       this.$store.commit('setIsLoading', true)
      }else{
       this.$store.commit('setIsLoading', false)
        return this.$store.getters.products

      }
    },
    cement_price(){
            return this.$store.getters.cement_price
        },
    stone_dust_price(){
        return this.$store.getters.stone_dust_price
    },
    
    cartTotalLength(){ // return total amount of blocks selected
            return this.cart.items.reduce((acc, curVal) => {
                return  acc += curVal.quantity 
            },
            1)
    },
  },
  components: {
    Location,
    ProductBox,
    ProductLoading,
    Cart,
    VueGoogleAutocomplete,
    ProductLoading
},
  methods:{
     getAddressData: function (addressData, placeResultData, id) {
        this.address = addressData;
        this.placeResultData = placeResultData

        const origin = new google.maps.LatLng(0.31743, 32.59437)
        const destination = new google.maps.LatLng(this.address.latitude, this.address.longitude)

        const location_name = this.address.locality
        store.commit('setLocationName', location_name)
        
        const service = new google.maps.DistanceMatrixService();
        service.getDistanceMatrix(
          {
            origins: [origin],
            destinations: [destination],
            travelMode:'DRIVING',
            unitSystem: google.maps.UnitSystem.metric,
            avoidHighways: false,
            avoidTolls: false
        }, callback);

        function callback(response, status){
          if (status != google.maps.DistanceMatrixStatus.OK){
            $('#result').html(err);
          }else {
            var origin = response.originAddresses[0];
            console.log(origin);
            var destination = response.destinationAddresses[0];
            console.log(destination);
            if (response.rows[0].elements[0].status === 'ZERO_RESULTS'){
              $('#result').html(
                'Please enter a site location or a location near the site'
              );
            }else {
              var distance = response.rows[0].elements[0].distance;
              console.log(distance);
              var distance_in_kilo = distance.value / 1000;
              console.log(distance_in_kilo)

              //get location info and set it to state
              
              
              const location_distance = distance_in_kilo
              store.commit('setLocationDistance', location_distance)

              if(distance_in_kilo < 60 ){
                const mobilisation_cost = 700000
                console.log(mobilisation_cost)
                //set mobilisation cost for region
                store.commit('setMobilisationCost', mobilisation_cost)
                
              }else {
                const mobilisation_cost = Math.ceil(700000 + ((distance_in_kilo - 60)* 2*5000));
                console.log(mobilisation_cost)
                store.commit('setMobilisationCost', mobilisation_cost)
              }
            }
            
          }
          }
        

      },
      ...mapActions(["fetchAllProducts"]),
      checkLocation(){
        swal({
            title:"Enter a site location",
            icon:"warning",
            button:"OK"
        })
      },
      checkTotal(){
        swal({
              title: "Sorry!!",
              text:"Total quantity should be above 5000 or contact us at tofali@ecoconcrete.co.ug, 039 3240090 , for units below 5000",
              icon:"warning",
              button: "OK"
        }),
         this.$store.commit('clearTotal') 
         this.updateProducts += 1 //hot reload products list
         
      },

      scrollToTop(){
      window.scrollTo(0,0)
      },

      createInvoice(){
      axios.get('/api/v1/get-invoice-number/')
            .then(response => {
            this.invoiceNumber = response.data.number
            this.$store.commit('setInvoiceNumber', this.invoiceNumber)
      })
      }

      },
      created(){
        this.fetchAllProducts();
      },


    onChange: function(e){
      var id = e.target.key;
      var name = e.target.options[e.target.options.selectedIndex].text;
      console.log('id', id);
      console.log('name', name);

    },

    addToCart(){
      if (isNaN(this.quantity) || this.quantity < 1){
        this.quantity = 1
      }

      var e = document.getElementById('blocks');

      this.product = e.options[e.selectedIndex].value;

      const item = {
        product: JSON.parse(this.product), //covert stringified product to JSON
        quantity: this.quantity
      }

      if (this.quantity > 5000){
        this.$store.commit('addToCart', item)
      }

      console.log(item)

    },

    


  }
</script>

<style scoped>
.section{
  padding-top: 5px;
  padding-bottom: 5px;
}
.is-success{
  background-color:#004100;
}

</style>
