<template>
  <div>
    <nav class="navbar is-dark is-fixed-top">
      <div class="navbar-brand">
        <router-link to="/" class="navbar-item">
          <!--<img src="https://res.cloudinary.com/celestialmk/image/upload/v1656186253/gxba9p09dsp6qs68sipl.png" alt="">
          
        <strong>TOFALI MATERIALS ESTIMATOR</strong>-->
          <img
            src="https://res.cloudinary.com/celestialmk/image/upload/v1656789200/owpuw98v7us7vnd6fvok.png"
            alt=""
          />
        </router-link>

        <a
          class="navbar-burger"
          aria-label="menu"
          aria-expanded="false"
          data-target="navbar-menu"
          @click="showMobileMenu = !showMobileMenu"
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div
        class="navbar-menu"
        id="navbar-menu"
        v-bind:class="{ 'is-active': showMobileMenu }"
      >
        <div class="navbar-start"></div>

        <div class="navbar-end has-text-left">
          <router-link to="/" class="navbar-item">Home</router-link>
          <router-link
            to="/materials-calculator"
            class="navbar-item"
            @click="clearCart()"
            >Materials Calculator</router-link
          >
          <a href="https://shop.tofaliafrica.com" class="navbar-item">Materials Shop</a>

          <a href="https://plans.tofaliafrica.com" class="navbar-item">Building Plans</a>

          <router-link to="/carbon-footprint-calculator" class="navbar-item"
            >Carbon Footprint</router-link
          >
          <router-link to="/documents" class="navbar-item"
            >Documents</router-link
          >


          <router-link to="/about" class="navbar-item">About</router-link>

          <div class="navbar-item" v-if="$store.state.isAuthenticated">
            <h5 class="">Logged in as {{ $store.state.username }}</h5>
          </div>
          <div class="navbar-item" v-if="$store.state.isAuthenticated">
            <button
              @click="logout()"
              class="button is-success"
              style="background-color: #004100"
            >
              Log out
            </button>
          </div>
          <div class="navbar-item" v-else>
            <router-link
              to="/sign-up"
              class="button is-success mr-2"
              style="background-color: #004100"
              >Sign Up</router-link
            >
            <router-link
              to="/log-in"
              class="button is-success"
              style="background-color: #004100"
              >Log In</router-link
            >
          </div>
        </div>
      </div>
    </nav>

    <router-view />

    <footer id="footer">
      <div class="widgetized-footer has-text-left">
        <div class="container">
          <div class="row">
            <div class="column is-4">
              <div class="footer-widget">
                <img
                  src="https://res.cloudinary.com/celestialmk/image/upload/v1656789200/owpuw98v7us7vnd6fvok.png"
                  class="mb-1"
                  alt=""
                />
                <p class="has-text-white">
                  Eco Concrete Ltd runs a business model of producing cement
                  based materials (blocks, planters, pipes and pavers etc.) on
                  construction sites. By deploying our industrial scale machines
                  on construction sites, including the remotest places in
                  Uganda, we reduce the cost of the materials we provide by
                  about 30% and construction costs by up to 20%.
                </p>
              </div>
            </div>
            <div class="column is-4">
              <div class="footer-widget">
                <div class="">
                  <h3 class="widget-title">Get In Touch</h3>
                </div>
                <ul class="menu-links has-text-white">
                  <li class="">
                    ECO CONCRETE LIMITED
                    <br />
                    Plot 27, Central Road, Kampala
                  </li>
                  <li>P.O. Box 37, Ntinda - Kampala</li>
                  <li>+256 393 240 090</li>
                  <li>
                    Email: tofali@ecoconcrete.co.ug
                  </li>
                </ul>
              </div>
            </div>
            <div class="column is-4">
              <div class="footer-widget">
                <div class="">
                  <h3 class="widget-title">Important Links</h3>
                </div>
                <ul class="mb-2">
                  <li>
                    <router-link to="/about">
                      <a class="has-green-text">About Us</a>
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/materials-calculator">
                      <a class="has-green-text" @click="clearCart()"
                        >Materials Calculator</a
                      >
                    </router-link>
                  </li>
                  <li>
                    <a href="https://ecoconcrete.co.ug/">Company Profile</a>
                  </li>
                  <li>
                    <router-link to="/">
                      <a class="">Home</a>
                    </router-link>
                  </li>
                  <div class="columns is-12 is-mobile">
                    <div class="is-4" >
                      <span class="icon ml-3 mt-3"><a href="https://twitter.com/TofaliAfrica"><i class="fab fa-twitter fa-lg" aria-hidden="true" style="color:#41c373"></i></a></span>
                      
                    </div>
                    <div class="is-4">
                      <span class="icon ml-3 mt-3"><a href="https://www.facebook.com/profile.php?id=100093655031029"><i class="fab fa-facebook fa-lg" aria-hidden="true" style="color:#41c373"></i></a></span>
                    </div>
                    <div class="is-4">
                      <span class="icon ml-3 mt-3"><a href="https://instagram.com/tofali_africa?igshid=OGQ5ZDc2ODk2ZA=="><i class="fab fa-instagram fa-lg" aria-hidden="true" style="color:#41c373"></i></a></span>
                    </div>
                  </div>
                  <!--
                  <li>
                      <router-link to="/portfolio">
                        <a class="">Portfolio</a>
                      </router-link>
                    </li>
                  -->
                  <li>
                    <a href='https://play.google.com/store/apps/details?id=com.tofaliafrica.client.twa&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                    <img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/></a>
                  </li>
                  <li>TofaliAfrica Sept 10 Version</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="copyright-footer pt-2 pl-3"
        style="background-color: rgba(0, 0, 0, 0.9)"
      >
        <div class="container">
          <ul class="mb-2 has-text-left">
            <!--

                  <li><a href="">Team</a></li>
                <li><a href="">Blog</a></li>
                -->

            <!--

                <li><a href="">Contact Us</a></li>
                <li><a href="">Privacy Policy</a></li>
              -->
          </ul>
          <p class="has-text-center has-text-white">
            &copy; {{new Date().getFullYear()}} Tofali Africa. A platform for Eco Concrete Ltd Clients.
          </p>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import update from './mixins/update'

export default {
  data() {
    return {
      showMobileMenu: false,
      cart: {
        items: [],
      },
    };
  },
  mixins: [update],
  computed: {
    ...mapGetters(["products"]),

    allProducts() {
      return this.products;
    },
  },
  
  beforeCreate() {
    this.$store.commit("initializeStore");

    const token = this.$store.state.token;

    if (token) {
      axios.defaults.headers.common["Authorization"] = "Token " + token;
    } else {
      axios.defaults.headers.common["Authorization"] = "";
    }
  },
  mounted() {
    this.cart = this.$store.state.cart;
    //this.username = this.$store.state.username
  },
  methods: {
    ...mapActions(["fetchAllProducts"]),
    logout() {
      axios.defaults.headers.common["Authorization"] = "";

      localStorage.removeItem("token");
      localStorage.removeItem("username");
      //localStorage.removeItem("userid")

      this.$store.commit("removeToken");
      this.$store.commit("removeUsername");

      this.$router.push("/results");
    },
    clearCart() {
      this.$store.commit("clearCart");
    },
  },
};
</script>

<style lang="scss">
@import "../node_modules/bulma";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.navbar-brand {
  min-height: 3.25rem;
  display: flex;
}

nav {
  a {
    &.router-link-exact-active {
      color: rgb(0, 65, 0);
    }
  }
}

.is-success {
  background-color: #004100;
}

.lds-dual-ring {
  display: inline-block;
  width: 60px;
  height: 60px;
}
.lds-dual-ring:after {
  content: " ";
  box-sizing: border-box;
  display: block;
  width: 100%;
  height: 100%;
  margin: 8px;
  border-radius: 50%;
  border: 10px solid #162534;
  border-top-color: #4bc8eb;
  border-bottom-color: #f13a8f;
  animation: lds-dual-ring 5s linear infinite;
}

.loader-inner {
  border-top-color: #36f372;
  border-bottom-color: #fff;
  animation-duration: 2.5s;
}

@keyframes lds-dual-ring {
  0% {
    transform: scale(1) rotate(360deg);
  }
  50% {
    transform: scale(0.8) rotate(-360deg);
  }
  100% {
    transform: scale(1) rotate(360deg);
  }
}

.is-loading-bar {
  height: 0;
  overflow: hidden;

  -webkit-transition: all 0.3s;
  transition: all 0.3s;

  &.is-loading {
    height: 65px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 567px) {
  #footer .widgetized-footer {
    padding: 40px 0 15px 0;
  }
}
@media only screen and (min-width: 769px) {
  #footer .footer-widget h3.widget-title {
    margin-bottom: 10px;
  }
  .row {
    display: flex;
  }
}
#footer .widgetized-footer {
  background-color: rgba(0, 0, 0, 0.9);
  padding: 60px 0 0 0;
}
#footer .footer-widget h3.widget-title {
  margin: 0 0 20px 0;
  padding: 0 0 15px 0;
  color: #fff;
  position: relative;
}
#footer .footer-widget h3 {
  font-size: 26px;
  font-weight: bold;
  line-height: 1.5em;
}
#footer .footer-widget ul.menu-links li {
  display: block;
  padding: 5px 0;
  color: #aaa;
}
#footer {
  background-image: url();
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin: 0;
  padding: 0;
  background-color: #28282e;
  font-weight: 400;
  color: #76767b;
}

img.mb-1 {
  max-width: 200px;
}

img {
  vertical-align: bottom;
  height: auto;
  border: 0;
}
</style>
